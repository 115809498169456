import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { BsHouseDoor } from "react-icons/bs";
import { Link } from "react-router-dom";

const HomeBreadCrumb: React.VFC = () => {
  const homeItemClass = "d-flex align-items-center";
  return (
    <Breadcrumb.Item
      active={false}
      linkAs={Link}
      linkProps={{ to: "/", className: homeItemClass }}
      className={homeItemClass}
    >
      <BsHouseDoor className="mr-2" />
      Home
    </Breadcrumb.Item>
  );
};

export default HomeBreadCrumb;
