import { NotificationType, useNotification } from "@gelsenwasser/react";
import axios, { AxiosError } from "axios";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { useContext, useRef, useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { ApiContext } from "services/ApiContext";

export const useUploadFiles = (
  profileId: string
): UseMutationResult<void, AxiosError, Array<File>> & {
  progress: number;
  abortControllerRef: React.MutableRefObject<AbortController | null>;
} => {
  const { logAxiosError } = useErrorLogger();
  const { addNotification } = useNotification();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  const UploadFiles = async (data: Array<File>, profileId: string) => {
    log.debug({ obj: data }, "uploading files to profileId %s", profileId);

    abortControllerRef.current = new AbortController();

    const formData = new FormData();
    data.forEach((element) => {
      formData.append("files", element);
    });

    const response = await api.post(`api/Files/${profileId}`, formData, {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
      signal: abortControllerRef.current.signal,
    });
    log.trace({ obj: response }, "result from uploading files to profileId %s", profileId);
  };

  const mutation = useMutation<void, AxiosError, Array<File>>((data: Array<File>) => UploadFiles(data, profileId), {
    onSettled() {
      queryClient.invalidateQueries(["FileListing", "import", profileId]);
    },
    onError: (err) => {
      if (axios.isCancel(err)) addNotification("", "Upload wurde abgeborchen", NotificationType.Alert);
      else logAxiosError("Error uploading Files", "Fehler beim Upload der Dateien", err);
    },
  });

  return { ...mutation, progress, abortControllerRef };
};

export const useDeleteFile = (profileId: string): UseMutationResult<void, AxiosError, string> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();

  const DeleteFile = async (fileName: string, profileId: string) => {
    log.debug("deleteting file %s in profile %s", fileName, profileId);
    const response = await api.delete(`/api/Files/${profileId}?fileName=${fileName}`);
    log.trace({ obj: response }, "result from deleting file %s in profile %s", fileName, profileId);
  };

  return useMutation<void, AxiosError, string>((fileName: string) => DeleteFile(fileName, profileId), {
    onSuccess() {
      queryClient.invalidateQueries(["FileListing", "export", profileId]);
    },
    onError: (err) => logAxiosError("Error deleting file", "Fehler beim Löschen der Datei", err),
  });
};
