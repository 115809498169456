import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownButton, Nav } from "react-bootstrap";

import { User } from "oidc-client";
import { AuthContext } from "../services/authentication";

const UserSettings: React.VFC = () => {
  //TODO: ggf. nur den Namen im State ablegen; entfernt auch die Warnung dass ggf. kein Titel im DropDownButton vorhanden ist
  const [userData, setUserData] = useState<User>();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    // falls die Seite neu geladen wird und die Daten noch nicht geladen wurden ...
    let isCanceled = false;

    const getUserData = async () => {
      const user = await authContext.getUser();
      if (user != null && !isCanceled) setUserData(user);
    };

    getUserData();

    // beim unmount das Setzen des State vermeiden
    return () => {
      isCanceled = true;
    };
  }, [authContext]);

  return (
    <Nav.Item className="ml-3">
      <DropdownButton
        title={[userData?.profile.given_name, userData?.profile.family_name].join(" ") ?? "n/a"}
        variant="outline-white"
        className="loginButton"
        alignRight
        style={{ zIndex: 1001 }}
      >
        <Dropdown.Item onClick={authContext.logout}>Abmelden</Dropdown.Item>
      </DropdownButton>
    </Nav.Item>
  );
};

export default UserSettings;
