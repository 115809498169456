import { IIdentityConfig } from "./AuthService";

const myBasePath = document.location.protocol + "//" + document.location.host;

interface ExtendedIdentityConfig extends IIdentityConfig {
  metadataUrl?: string;
}

export const IDENTITY_CONFIG: ExtendedIdentityConfig = {
  authority: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_POLICY_NAME}`,

  client_id: process.env.REACT_APP_CLIENT_ID ?? "",
  redirect_uri: myBasePath + "/signin-oidc",
  post_logout_redirect_uri: myBasePath + "/signout-oidc",
  response_type: "code",
  scope: `openid profile ${process.env.REACT_APP_API_SCOPE}`,
  automaticSilentRenew: false,
  loadUserInfo: false,
  metadataUrl: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_POLICY_NAME}/v2.0/.well-known/openid-configuration`,
};
