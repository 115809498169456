// because we can't easily get rid of the any here
/* eslint-disable @typescript-eslint/no-explicit-any */

import { UserManager, Log, User, WebStorageStateStore, SigninRequest } from "oidc-client";
import { log } from "../../common/Logger";

export interface IIdentityConfig {
  authority: string;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  response_type: string;
  response_mode?: string;
  scope: string;
  automaticSilentRenew: boolean;
  loadUserInfo: boolean;
}

export interface IAuthService {
  signinRedirectCallback(): void;
  logout(): void;
  signoutRedirectCallback(): void;
  isAuthenticated(): boolean;
  signinRedirect(): void;
  signinSilentCallback(): void;
  createSigninRequest(): void;
  getUser(): Promise<User | null>;
}
export default class AuthService implements IAuthService {
  private _userManager: UserManager;

  constructor(config: IIdentityConfig) {
    this._userManager = new UserManager({
      ...config,
      userStore: new WebStorageStateStore({
        store: window.sessionStorage as any,
      }),
    });

    Log.logger = log;
    Log.level = Log.WARN;
    this._userManager.events.addUserLoaded((user: User) => {
      log.debug({ obj: user }, "user loaded");
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this._userManager.events.addSilentRenewError((e: Error) => {
      log.debug("silent renew error", e.message);
      this.signinRedirect();
    });

    this._userManager.events.addAccessTokenExpired(() => {
      log.debug("token expired, signin-silent");
      this.signinSilent();
    });
  }

  signinRedirectCallback = (): void => {
    this._userManager.signinRedirectCallback();
    // .then(() => {
    //   //window.location.replace("http://localhost:61933");
    //   "";
    // });
  };

  getUser = async (): Promise<User | null> => {
    const user = await this._userManager.getUser();
    //if (!user) {
    //  return await this.UserManager.signinRedirectCallback();
    //}
    return user;
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (): void => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this._userManager.signinRedirect({});
  };

  navigateToScreen = (): void => {
    const redir = localStorage.getItem("redirectUri");
    if (redir) window.location.replace(redir);
    else window.location.replace("/");
  };

  isAuthenticated = (): boolean => {
    const value = sessionStorage.getItem(
      `oidc.user:${this._userManager.settings.authority}:${this._userManager.settings.client_id}`
    );
    const oidcStorage: any = !value ? null : JSON.parse(value);

    return !!oidcStorage && !!oidcStorage.id_token;
  };

  signinSilent = (): void => {
    log.debug("clearing session to remove selected kundenid at login");
    sessionStorage.clear();
    this._userManager
      .signinSilent()
      .then((user: User) => {
        log.debug("signed in", user);
      })
      .catch((err: any) => {
        log.debug(err);
      });
  };

  signinSilentCallback = (): void => {
    this._userManager.signinSilentCallback();
  };

  createSigninRequest = (): Promise<SigninRequest> => {
    return this._userManager.createSigninRequest();
  };

  logout = (): void => {
    this._userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this._userManager.clearStaleState();
    log.debug("clearing session to remove selected kundenid at logout");
    sessionStorage.clear();
  };

  signoutRedirectCallback = (): void => {
    this._userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace("/");
    });
    this._userManager.clearStaleState();
  };
}
