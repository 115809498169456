import * as React from "react";
import AuthService, { IAuthService, IIdentityConfig } from "./AuthService";

export interface AuthProviderProps {
  config: IIdentityConfig;
}

export const AuthContext = React.createContext<IAuthService>({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => false,
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    getUser: () => new Promise((_) => null),
  });

export const AuthConsumer : React.Consumer<IAuthService> = AuthContext.Consumer;

export class AuthProvider extends React.Component<React.PropsWithChildren<AuthProviderProps>> {
  authService: AuthService;

  constructor(props: AuthProviderProps) {
    super(props);
    this.authService = new AuthService(props.config);
  }

  render(): JSX.Element {
    return (
      <AuthContext.Provider value={this.authService}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
