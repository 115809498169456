import { useContext } from "react";
import { AxiosError } from "axios";
import { QueryFunctionContext, useQuery, UseQueryResult } from "react-query";
import { useErrorLogger } from "common/LoggerHooks";
import { ApiContext } from "services/ApiContext";
import { log } from "common/Logger";
import { parseISO } from "date-fns";

export interface FileListItem {
  fullName: string;
  length: number;
  name: string;
  lastModifiedUtc: Date;
}

export type DirectionType = "export" | "import";

export const useGetFileListing = (
  direction: DirectionType,
  profileId: string
): UseQueryResult<Array<FileListItem>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetFileListing = async ({
    queryKey,
  }: QueryFunctionContext): Promise<Array<FileListItem>> => {
    const [ignored, direction, profileId] = queryKey;
    log.debug(
      "getting file listing for direction %s from profileId %s",
      direction,
      profileId
    );
    const response = await api.get(
      `/api/Files/${profileId}?folderType=${direction}`
    );
    log.trace(
      { obj: response },
      "result from get file listing for profileId %s",
      profileId
    );
    return response.data.map((item) => {
      return {
        fullName: item.fullName,
        length: item.length,
        name: item.name,
        lastModifiedUtc: parseISO(item.lastModifiedUtc),
      };
    });
  };

  return useQuery<Array<FileListItem>, AxiosError>({
    queryKey: ["FileListing", direction, profileId],
    queryFn: GetFileListing,
    onError: (err) =>
      logAxiosError(
        "Error loading filelisting",
        "Fehler beim Laden der Dateien",
        err
      ),
  });
};
