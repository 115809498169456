import React, { useEffect, useState } from "react";
import { Col, Row, Container, Breadcrumb, Tabs, Tab, Modal, Button, ProgressBar } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BsPlus, BsXCircle } from "react-icons/bs";
import FileListing from "../FileListing/FileListing";
import { ActionButton, ContentBox, Upload } from "@gelsenwasser/react";
import { DirectionType } from "../FileListing/FileQueries";
import { log } from "common/Logger";
import { useUploadFiles } from "../FileListing/FileMutations";
import { useGetProfiles } from "./ProfileQueries";
import HomeBreadCrumb from "components/HomeBreadCrumb";

const ProfileDisplay: React.VFC = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { profileId } = useParams();

  // states
  const [activeTab, setActiveTab] = useState<DirectionType>("export");
  const [profileName, setProfileName] = useState("n/a");
  const [stagedFiles, setStagedFiles] = useState<Array<File>>([]);
  const [showUpload, setShowUpload] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // parameter
  // queries und mutationen
  const uploadFiles = useUploadFiles(profileId ?? "");
  const { data: profilesData } = useGetProfiles(!location.state);

  // effekte
  useEffect(() => {
    if (!location.hash) return;

    const direction = location.hash.substring(1).toLowerCase();
    if (direction === "export" || direction === "import") {
      log.debug("setting %s as active tab because of hash-value", direction);
      setActiveTab(direction);
    }
  }, [location.hash]);

  useEffect(() => setUploadProgress(uploadFiles.progress), [uploadFiles.progress]);

  useEffect(() => {
    log.debug("trying to determine profile-name");
    if (location.state) {
      log.debug("location state is set, will retrieve profile-name from state");
      setProfileName(location.state.name);
    } else if (profilesData) {
      log.debug("location state is not set, will query for profiles with id %s", profileId);
      const found = profilesData.find((x) => x.id === profileId);
      if (found) {
        log.debug("found profile with id %s", profileId);
        setProfileName(found.name);
      }
    }
  }, [profileId, profilesData, location.state]);

  // daten

  // handler
  const handleTabSelection = (key: string | null) => {
    if (key !== null && (key === "export" || key === "import")) {
      setActiveTab(key);
      navigate("#" + key, { state: location.state });
    }
  };

  const handleDoFileUpload = () => {
    log.debug("got %s files for upload", stagedFiles.length);
    uploadFiles.mutate(stagedFiles, {
      onSettled: () => {
        setShowUploadProgress(false);
        setUploadProgress(0);
      },
    });
    setShowUploadProgress(true);
    setShowUpload(false);
  };

  const handleShowUpload = () => setShowUpload(true);
  const handleHideUpload = () => setShowUpload(false);
  const handleCancelUpload = () => uploadFiles.abortControllerRef.current?.abort();

  return (
    <>
      <Container>
        <Breadcrumb className="mt-3 d-print-none">
          <HomeBreadCrumb />
          <Breadcrumb.Item active>{profileName}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container>
        <Row className="mt-0">
          <Col md={8}>
            <ContentBox title="Dateien">
              <Tabs id="fileListingTabs" activeKey={activeTab} onSelect={handleTabSelection}>
                <Tab eventKey="export" title="Herunterladen" />
                <Tab eventKey="import" title="Hochladen">
                  <ActionButton caption="Upload Dialog" icon={BsPlus} className="my-4" onClick={handleShowUpload} />
                </Tab>
              </Tabs>
              <FileListing direction={activeTab} profileId={profileId ?? ""} />
            </ContentBox>
          </Col>
        </Row>
      </Container>
      <Modal show={showUpload} onHide={handleHideUpload} backdrop={false} centered>
        <Modal.Header closeButton>
          <Modal.Title> Uploads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Upload
            caption="Bitte laden Sie hier ihre Dateien hoch"
            subCaption="Folgende Dateitypen werden unterstützt: CSV, TXT, PNG, JPG (max 30 MB)"
            acceptedMimeTypes={["text/csv", "text/plain", "image/png", "image/jpeg"]}
            onFilesChanged={setStagedFiles}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideUpload}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleDoFileUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUploadProgress} backdrop={false} centered>
        <Modal.Body className="d-flex flex-column">
          <div>
            Übertrage {stagedFiles.length} Datei{stagedFiles.length > 1 ? "en" : ""} ...
          </div>
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
          <ActionButton
            caption="Upload Abbrechen"
            icon={BsXCircle}
            onClick={handleCancelUpload}
            className="mt-4 align-self-center"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileDisplay;
