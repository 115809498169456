import React, { useContext } from "react";

import { AuthContext } from "../services/authentication";

import Home from "./Home";

import Splash from "./Splash";

export const Main: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  if (!isAuthenticated) return <Splash />;
  else return <Home />;
};

export default Main