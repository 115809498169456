import React, { ReactNode, useContext } from "react";

import { Footer, FooterListClassname, Header } from "@gelsenwasser/react";

import { AuthContext } from "services/authentication";
import UserSettings from "./UserSettings";

interface Props {
  children: ReactNode;
}

export const Layout: React.FC<Props> = ({ children }: Props) => {
  const authContext = useContext(AuthContext);

  return (
    <>
      <Header
        noWhiteBackground
        title={
          <>
            <strong>GW-Share</strong>
            <span className="font-weight-light"> Portal</span>
          </>
        }
        environment={process.env.REACT_APP_ENV}
      >
        {authContext.isAuthenticated() && (
          <>
            <UserSettings />
          </>
        )}
      </Header>

      <main className="d-flex flex-column flex-fill outermain bg-gw-light">
        {children}
      </main>

      <Footer
        year={process.env.REACT_APP_YEAR}
        version={process.env.REACT_APP_VERSION}
      >
        <ul className={FooterListClassname}>
          <li>
            <a
              href="https://www.gelsenwasser.de/impressum"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
          </li>
          <li>
            <a
              href="https://www.gelsenwasser.de/datenschutz"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>
          </li>
        </ul>
      </Footer>
    </>
  );
};

export default Layout;
