import { AxiosError } from "axios";
import { useErrorLogger } from "common/LoggerHooks";
import { log } from "common/Logger";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { ApiContext } from "services/ApiContext";

const defaultLookupStaleTime = 15 * 60 * 1000; /* 15 Minuten, Default: 0 Minuten */
const defaultLookupCacheTime = 5 * 60 * 1000; /* Default: 5 Minuten */

export interface Profile {
  id: string;
  name: string;
}

export const useGetProfiles = (enabled = true): UseQueryResult<Array<Profile>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetProfile = async (): Promise<Array<Profile>> => {
    log.debug("getting Profiles");
    const response = await api.get("/api/Profiles");
    log.trace({ obj: response }, "result from Profiles");
    return response.data;
  };

  return useQuery<Array<Profile>, AxiosError>({
    queryKey: "GetProfile",
    queryFn: GetProfile,
    enabled,
    onError: (err) => {
      if (err.response?.status !== 404) logAxiosError("Error loading Profiles", "Fehler beim Laden der Profile", err);
    },
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
  });
};
