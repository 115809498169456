import React from "react";
import { Route, Routes } from "react-router-dom";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "@gelsenwasser/react/lib/Styles/gwag-theme.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { NotificationProvider } from "@gelsenwasser/react";
import { IDENTITY_CONFIG } from "./services/authentication/AuthConst";
import { AuthProvider, LogInCallback, LogOutCallback } from "./services/authentication";

// static components
import Layout from "./components/Layout";
import Main from "./domain/Main";
import NoMatch from "./domain/NoMatch";
import { ApiContextProvider } from "./services/ApiContext";
import ProfileDisplay from "domain/Profiles/ProfileDisplay";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

const queryClient = new QueryClient();
export default class App extends React.Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AuthProvider config={IDENTITY_CONFIG}>
            <ApiContextProvider>
              <NotificationProvider>
                <QueryClientProvider client={queryClient}>
                  <Layout>
                    <Routes>
                      {/* Home */}
                      <Route path="/" element={<Main />} />
                      <Route path="/listing/:profileId" element={<ProfileDisplay />} />

                      {/* Authentication */}
                      <Route path="/signin-oidc" element={<LogInCallback />} />
                      <Route path="/signout-oidc" element={<LogOutCallback />} />

                      {/* Unbekannte Seite */}
                      <Route path="*" element={<NoMatch />} />
                    </Routes>
                  </Layout>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </NotificationProvider>
            </ApiContextProvider>
          </AuthProvider>
        </AppInsightsContext.Provider>
      </>
    );
  }
}
