import React, { useCallback, useContext, useMemo } from "react";
import {
  DataTableAdvanced,
  DateCell,
  ListActionButton,
} from "@gelsenwasser/react";
import { CellProps, Column, SortingRule } from "react-table";
import { BsDownload, BsTrash } from "react-icons/bs";

import { DirectionType, FileListItem, useGetFileListing } from "./FileQueries";
import { FormatBytes, JsDownloadFile } from "helper";
import { log } from "common/Logger";
import { ApiContext } from "services/ApiContext";
import { useDeleteFile } from "./FileMutations";

const initialSorting: Array<SortingRule<FileListItem>> = [
  { id: "lastModifiedUtc", desc: true },
];

export interface FileListingProps {
  direction: DirectionType;
  profileId: string;
}

const FileListing: React.VFC<FileListingProps> = (props) => {
  // hooks
  const { api } = useContext(ApiContext);

  // states
  // parameter
  // queries und mutationen
  const fileListingQuery = useGetFileListing(props.direction, props.profileId);
  const deleteFile = useDeleteFile(props.profileId);

  // effekte
  // daten
  // handler
  const handleDownloadFile = useCallback(
    (fileName: string, fullPathFileName: string) => async () => {
      //noop
      log.debug("will download file with name %s", fullPathFileName);
      const response = await api.get(
        `/api/Files/${props.profileId}/Content?fileName=${fullPathFileName}`,
        { responseType: "blob" }
      );
      JsDownloadFile(response.data, fileName);
    },
    [api, props.profileId]
  );

  const handleDeleteFile = useCallback(
    (fileName: string) => () => {
      deleteFile.mutate(fileName);
    },
    [deleteFile]
  );

  const columns: Array<Column<FileListItem>> = useMemo(() => {
    return [
      { Header: "Dateiname", accessor: "name" },
      {
        Header: "Größe",
        accessor: "length",
        disableGlobalFilter: true,
        Cell: ({ value }: CellProps<FileListItem, number>) =>
          FormatBytes(value),
      },
      {
        Header: "Datum",
        accessor: "lastModifiedUtc",
        disableGlobalFilter: true,
        Cell: DateCell,
      },
      {
        Header: "Aktion",
        Cell: ({
          row: {
            original: { fullName, name },
          },
        }: CellProps<FileListItem, never>) => (
          <>
            <ListActionButton
              title="Herunterladen"
              icon={BsDownload}
              onClick={handleDownloadFile(name, fullName)}
            />
            <ListActionButton
              title="Löschen"
              icon={BsTrash}
              hidden={props.direction !== "export"}
              onClick={handleDeleteFile(name)}
            />
          </>
        ),
      },
    ];
  }, [handleDownloadFile, handleDeleteFile, props.direction]);

  return (
    <DataTableAdvanced
      columns={columns}
      isLoading={fileListingQuery.isLoading}
      initialSorting={initialSorting}
      data={fileListingQuery.data}
      contentElementName="Dateien"
      globalSearchPlaceholder="Suche nach Dateinamen"
    />
  );
};

export default FileListing;
