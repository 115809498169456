import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const reactPlugin = new ReactPlugin();
if (process.env.REACT_APP_APPINSIGHTS) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APPINSIGHTS,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();
}
