import React from "react";
import { Link, Navigate } from "react-router-dom";
import { log } from "common/Logger";
import { Profile, useGetProfiles } from "./Profiles/ProfileQueries";
import { Breadcrumb, Col, Container, Row, Spinner } from "react-bootstrap";
import HomeBreadCrumb from "components/HomeBreadCrumb";
import { ContentBox } from "@gelsenwasser/react";
import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";

const renderProfileQueryResult = (profilesQuery: UseQueryResult<Profile[], AxiosError>) => {
  if (profilesQuery.isLoading)
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Suche nach Profilen...</span>
        </Spinner>
        Suche nach Profilen...
      </>
    );
  if (profilesQuery.data?.length === 0 || profilesQuery.isError) {
    log.warn("found no profile with for user");
    return <p>Kein Profile gefunden</p>;
  }
  return (
    <>
      <p>Sie haben mehrere Profile zur Auswahl:</p>
      <ul>
        {profilesQuery.data?.map((item) => (
          <li key={item.id}>
            <Link to={"/listing/" + item.id} state={item}>
              {item.name}
            </Link>{" "}
          </li>
        ))}
      </ul>
    </>
  );
};

export const Home: React.VFC = () => {
  // hooks
  // states
  // parameter
  // queries und mutationen
  const profilesQuery = useGetProfiles();

  // effekte
  // daten
  // handler

  log.debug("showing profile selection");

  if (!profilesQuery.isLoading && profilesQuery.data?.length === 1) {
    log.debug("found only one profile with id %s, will redirect to listing", profilesQuery.data[0].id);
    return <Navigate to={"/listing/" + profilesQuery.data[0].id} replace state={profilesQuery.data[0]} />;
  }

  return (
    <>
      <Container>
        <Breadcrumb className="mt-3 d-print-none">
          <HomeBreadCrumb />
        </Breadcrumb>
      </Container>
      <Container>
        <Row className="mt-0">
          <Col md={8}>
            <ContentBox title="Profileauswahl">{renderProfileQueryResult(profilesQuery)}</ContentBox>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
